<template>
  <div>
    <b-card>
      <h4>ProductDescription</h4>
      <b-form-textarea
        id="textarea-default"
        placeholder="Textarea"
        rows="3"
        class="my-1"
        v-model="info.short_description"
      />
      <h4>Long Description</h4>
      <quill-editor
        v-model="info.long_description"
        :options="snowOption"
        class="my-1"
      />
      <h4>Feature</h4>
      <b-form-tags v-model="info.feature" no-outer-focus>
        <template
          #default="{ tags, inputAttrs, inputHandlers, addTag, removeTag }"
        >
          <b-input-group aria-controls="my-custom-tags-list">
            <input
              v-bind="inputAttrs"
              placeholder="New tag - Press enter to add"
              class="form-control"
              v-on="inputHandlers"
            />
            <b-input-group-append>
              <b-button variant="primary" @click="addTag()"> Add </b-button>
            </b-input-group-append>
          </b-input-group>
          <ul
            id="my-custom-tags-list"
            class="list-unstyled d-inline-flex flex-wrap mb-0"
            aria-live="polite"
            aria-atomic="false"
            aria-relevant="additions removals"
          >
            <!-- Always use the tag value as the :key, not the index! -->
            <!-- Otherwise screen readers will not read the tag
                    additions and removals correctly -->
            <b-card
              v-for="tag in tags"
              :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
              :key="tag"
              tag="li"
              class="shadow-none border mt-1 mr-1 mb-0"
              body-class="py-1 pr-2 text-nowrap"
            >
              <strong>{{ tag }}</strong>
              <b-button
                variant="link"
                size="sm"
                :aria-controls="`my-custom-tags-tag_${tag.replace(
                  /\s/g,
                  '_'
                )}_`"
                class="py-0"
                @click="removeTag(tag)"
              >
                remove
              </b-button>
            </b-card>
          </ul>
        </template>
      </b-form-tags>

      <h4>Item Include</h4>
      <b-form-tags v-model="info.web_items_include" no-outer-focus>
        <template
          #default="{ tags, inputAttrs, inputHandlers, addTag, removeTag }"
        >
          <b-input-group aria-controls="my-custom-tags-list">
            <input
              v-bind="inputAttrs"
              placeholder="New tag - Press enter to add"
              class="form-control"
              v-on="inputHandlers"
            />
            <b-input-group-append>
              <b-button variant="primary" @click="addTag()"> Add </b-button>
            </b-input-group-append>
          </b-input-group>
          <ul
            id="my-custom-tags-list"
            class="list-unstyled d-inline-flex flex-wrap mb-0"
            aria-live="polite"
            aria-atomic="false"
            aria-relevant="additions removals"
          >
            <!-- Always use the tag value as the :key, not the index! -->
            <!-- Otherwise screen readers will not read the tag
                    additions and removals correctly -->
            <b-card
              v-for="tag in tags"
              :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
              :key="tag"
              tag="li"
              class="shadow-none border mt-1 mr-1 mb-0"
              body-class="py-1 pr-2 text-nowrap"
            >
              <strong>{{ tag }}</strong>
              <b-button
                variant="link"
                size="sm"
                :aria-controls="`my-custom-tags-tag_${tag.replace(
                  /\s/g,
                  '_'
                )}_`"
                class="py-0"
                @click="removeTag(tag)"
              >
                remove
              </b-button>
            </b-card>
          </ul>
        </template>
      </b-form-tags>

      <b-button size="sm" variant="success" @click="update">Save</b-button>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BFormTextarea,
  BButton,
  BFormTags,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "@/assets/scss/quill/quill.snow.css";

import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BCard,
    BFormTextarea,
    BButton,
    quillEditor,
    BFormTags,
    BInputGroup,
    BInputGroupAppend,
  },
  data: () => ({
    form: {},
    snowOption: {
      theme: "snow",
    },
    content: ``,
  }),
  created() {},
  props: ["info"],
  methods: {
    update() {
      this.form.short_description = this.info.short_description;
      this.form.long_description = this.info.long_description;
      this.form.feature = this.info.feature;
      this.form.web_items_include = this.info.web_items_include;

      this.$http({
        method: "PUT",
        url: `/product-kit/` + this.$route.params.id,
        data: this.form,
      })
        .then((x) => {
          this.$root.$emit("edit-product-complete");
        })
        .catch((e) => {
          console.log("image upload Error", e);
        });
    },
  },
};
</script>
