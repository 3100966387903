<template>
  <div>
    <b-card>
      <h3>Product Attribute</h3>
      <div class="row" v-for="(x, index) in attribute_list" :key="x.eg">
        <div class="col-lg-2">
          <b-form-checkbox v-model="checkbox[x.slug]" :value="true">
            {{ x.web_th }} [{{ x.slug }}]
          </b-form-checkbox>
        </div>
        <div class="col-lg-9">
          <b-form-group>
            <b-form-tags
              v-model="form[x.slug]"
              no-outer-focus
              :disabled="!checkbox[x.slug]"
              :placeholder="x.eg"
              separator=",;"
              remove-on-delete
            >
            </b-form-tags>
            <div v-if="pre_suggestion[x.slug]">
              {{ pre_suggestion[x.slug] }}
            </div>
          </b-form-group>
        </div>
        <div class="col-1">
          <b-badge @click="showTag(x.slug, x.web_th)" class="mr-1 ml-auto">
            i
          </b-badge>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-2"></div>
        <div class="col-lg-9">
          <b-button size="sm" variant="success" @click="update">Save</b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BFormGroup,
  BFormTags,
  BFormCheckbox,
} from "bootstrap-vue";
import attribute from "@/assets/attribute.json";
import _ from "lodash";
export default {
  props: ["info"],
  components: {
    BBadge,
    BCard,
    BFormGroup,
    BFormTags,
    attribute,
    BFormCheckbox,
    BButton,
  },
  data() {
    return {
      form: {},
      checkbox: {},
      selected: false,
      attribute_list: attribute,
      pre_suggestion: {},
    };
  },
  created() {
    // this.init_form();
  },
  watch: {
    info() {
      this.init_form();
    },
  },
  computed: {
    suggestion() {
      return this.pre_suggestion;
    },
  },
  methods: {
    showTag(slug, web_th) {
      this.$http({
        method: "POST",
        url: `/product-kit/attribute-tag`,
        data: {
          slug: slug,
        },
      })
        .then((x) => {
          this.pre_suggestion = Object.assign({}, this.pre_suggestion, {
            [slug]: x.data.data,
          });
        })
        .catch((e) => {});
    },
    init_form() {
      const pre = {};
      const pre_check = {};
      const result = this.info.attribute.reduce((r, a) => {
        r[a.key] = r[a.key] || [];
        r[a.key].push(a.value);
        return r;
      }, Object.create(null));

      attribute.forEach((x) => {
        if (result[x.slug]) {
          pre[x.slug] = result[x.slug];
          pre_check[x.slug] = true;
        }
      });

      this.form = pre;
      this.checkbox = pre_check;
    },
    async update() {
      const checkbox_true = _.omitBy(this.checkbox, (value, key) => {
        return !value;
      });
      const arr_key = _.keys(checkbox_true);

      const put_form = _.pickBy(this.form, function (v, k) {
        return _.includes(arr_key, k);
      });

      console.log("put_form");
      const arr = [];
      await Object.entries(put_form).forEach((x) => {
        const key = x[0];
        const pre_values = x[1];
        const value = [];
        pre_values.forEach((y) => {
          arr.push({
            key: key,
            value: y,
          });
        });
      });

      this.$http({
        method: "PUT",
        url: `/product-kit/` + this.$route.params.id,
        data: {
          attribute: arr,
        },
      })
        .then((x) => {
          this.toast_success("บันทึกสำเร็จ");
          this.$root.$emit("edit-product-complete");
        })
        .catch((e) => {
          console.log("image upload Error", e);
        });
    },
  },
};
</script>
