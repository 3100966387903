<template>
  <div>
    <product-kit-info :info="info" />
    <edit-kit-modal :kit_id="kit_id" />
    <product-in-kit :info="info" />

    <b-row>
      <b-col md="12">
        <image-upload :info="info"></image-upload>
      </b-col>
    </b-row>
    <attribute-info :info="info" />
    <product-description :info="info"></product-description>

    <!-- 
    <accessories-include />
    
     -->
  </div>
</template>

<script>
import { BCard, BCardText, BRow, BCol } from "bootstrap-vue";

import ProductInKit from "./component/ProductInKit.vue";
import ProductKitInfo from "./component/ProductKitInfo.vue";
import EditKitModal from "./component/EditKitModal.vue";
import ImageUpload from "./component/ImageUpload.vue";
import ProductDescription from "./component/ProductDescription.vue";
import AttributeInfo from "./component/AttributeInfo.vue";

export default {
  components: {
    BCard,
    BCardText,
    ProductKitInfo,
    EditKitModal,
    BRow,
    BCol,
    ProductInKit,
    ImageUpload,
    AttributeInfo,
    ProductDescription,
  },
  data() {
    return {
      kit_id: this.$route.params.id,
      info: {},
    };
  },
  mounted() {
    this.$root.$on("edit-product-complete", async () => {
      await this.loadData();
    });
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.$http({
        method: "GET",
        url: `/product-kit/view/` + this.$route.params.id,
      }).then((x) => {
        this.info = x.data.data;
      });
    },
  },
};
</script>

<style></style>
